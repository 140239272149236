import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import bird2 from '../assets/images/birds/bird3.png';
import Button from '../components/button';
// tabs

import 'react-tabs/style/react-tabs.css';

// transitionimage
import transitionimage from '../assets/images/transition.jpg';
// travelines
import Travelline from '../components/travellines/Travelline1';

const Page = styled.div`
  margin-top: 7.5vw;

  .siteWrapper {
    grid-column: 1 / span 24;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
    .page-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
      }
      grid-template-rows: masonry;
      grid-gap: 30px;
    }
  }
  header {
    grid-column: 5 / span 16;
    padding: 3.5vw 0 0 0;
    .back {
      margin-bottom: 20px;
      display: block;
      font-size: 1em;
    }
    .intro-text {
      max-width: 400px;
      .title {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
      }
      .sub-heading {
        font-size: 1.2rem;
      }
      h1 {
        font-size: 3em;
        padding-bottom: 10px;
      }
    }
  }

  min-height: 100vh;

  .tab-area {
    .react-tabs__tab {
      border-radius: 0;
      font-family: 'IBM Semi-Bold 600', sans-serif;
      font-size: 1em;
    }
    .react-tabs__tab--selected {
      background: var(--olive);
      color: var(--beige);
      border: 1px solid var(--grey);
    }
  }
`;

const Panel = styled.div`
  padding: 10px 10px;
  line-height: 1.2;
`;

const Bird = styled.div`
  position: absolute;
  left: -70px;
  top: 100px;

  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
  transform: translateY(-50%) rotate(0deg);
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Experiences() {
  return (
    <Page>
      <Bird>
        <img src={bird2} alt="" />
      </Bird>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <div className="intro-text">
              <AniLink
                class="link back b-i"
                to="/"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Back Home
              </AniLink>
              <h1>Information</h1>
              <p className="sub-heading b-i" />
            </div>
          </header>
          <div className="info-grid">
            <div className="tab-area">
              <Tabs>
                <TabList>
                  <Tab>COVID-19</Tab>
                  <Tab>Travel</Tab>
                  <Tab>Volunteer</Tab>
                  <Tab>Sustainability</Tab>

                  <Tab>Contact</Tab>
                </TabList>
                <TabPanel>
                  <Panel>
                    <h2>COVID-19</h2>
                    <div className="intro-body">
                      <p className="b">
                        For the 1st edition of Magnetic Fields Nomads we are
                        only offering a limited number of residential packages.
                        It will not be possible to attend the event unless you
                        are staying on site with accommodation booked through
                        us. We have made this decision in order to create as
                        safe and controlled environment as possible.
                      </p>
                      <p>
                        {' '}
                        <span className="b-i sub-title">
                          Creating a safe space during COVID
                        </span>
                        <br />
                        <br />
                        We have worked hard to create a set of guidelines to
                        ensure everyone’s safety.
                      </p>
                      <p>
                        A RT-PCR test is mandatory to attend within 48 hours
                        prior to checking in. This has to be done from a
                        certified test centre. There is no compromise with this
                        rule, if you fail to provide a negative test we will not
                        admit you into the event, your tickets and package will
                        not be refunded.
                      </p>
                      <p className="b-i">
                        Please make sure you read all the following information
                        carefully. Those attending can download a list of
                        approved nationwide labs below.
                        <br />
                        <br />
                        <Button
                          linkto="https://www.icmr.gov.in/pdf/covid/labs/COVID_Testing_Labs_05032021.pdf"
                          linktext="List of Approved Labs"
                        />
                      </p>
                      <div className="rust">
                        <p className="b ">
                          Disclaimer: Whilst all these labs promise delivery
                          within 24hrs, it’s very much the responsibility of the
                          attendee to check that they’ll get results in time of
                          the event. You will not be let on to site without a
                          valid RT-PCR test from within 48 hours of check-in. If
                          you have already had a Covid-19 vaccination, you will
                          still need to get tested prior to attending.
                          <br />
                          <br />
                          You must carry a print out of this, which we will keep
                          for our records. There are no exceptions to this rule.
                        </p>
                        <p className="b ">
                          We’re programming this event in a way that utilises
                          predominantly outdoor areas with a lot of space. The
                          event is a residential gathering and single/day
                          tickets are not going to be available.
                        </p>
                        <p className="b ">
                          The extensive venue will safely accommodate all
                          attendees and we have carefully programmed the event
                          using spaces that allow for a substantial distance for
                          each experience.
                        </p>
                        <p className="sb ">
                          All staff, volunteers, vendors, performers and
                          musicians will be tested, no exceptions. Over the
                          course of the weekend, we encourage you to not leave
                          the site during your stay. Face coverings will be
                          necessary when in close proximity to anyone not in
                          your immediate bubble. No drinks or food will be
                          served to anyone not wearing a face covering.
                        </p>
                      </div>
                      <p className="sb-i">Summary of Standards</p>
                      <ul>
                        <li>
                          <p>
                            Rooms will be sanitised prior to your arrival and
                            communal areas will be sanitised every few hours.
                          </p>
                        </li>
                        <li>
                          <p>
                            All touch points in public areas like door handles,
                            counter tops, table tops and railings, will be
                            cleaned continuously using a sanitiser/disinfectant.
                            These practices are also in place in guest rooms
                            during the morning housekeeping service.
                          </p>
                        </li>
                        <li>
                          <p>
                            Masks and disposable gloves will be worn by our team
                            members at all times and changed frequently.
                            Housekeeping staff use fresh gloves for every room
                            they service.
                          </p>
                        </li>
                        <li>
                          <p>
                            Sprayer with professionally identified chemicals are
                            being used to disinfect the venue entrances,
                            employee entrances, as well as various public areas.
                          </p>
                        </li>
                        <li>
                          <p>
                            For the safety of our guests, rooms will be
                            sanitised after guests check out, kept vacant for 48
                            hours and then allocated
                          </p>
                        </li>
                        <li>
                          <p>
                            Protocols are in place for staff in the kitchens,
                            restaurants, and bars to sanitise their hands every
                            time they serve food or touch food related items.
                          </p>
                        </li>
                        <li>
                          <p>
                            Temperature readings of all guests and staff will be
                            taken regularly across site and at key locations.
                          </p>
                        </li>
                        <li>
                          <p>
                            All luggage shall be sanitised before entering the
                            hotel.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel>
                    <h2>Travel Info</h2>
                    <p>
                      Click{' '}
                      <a className="b" href="https://nomads.transportal.co.in/">
                        Here
                      </a>{' '}
                      to book a car directly from our travel parter Transportal.
                    </p>
                    <p>
                      <span className="sb-i">By Road: </span>
                      <br />
                      Our Address: Ranthambhore Road, Village Khilchipur, Sawai
                      Madhopur, Rajasthan 322001, India.
                    </p>
                    <p>
                      Nahargarh is just 4.3 miles from Sawai Madhopur Railway
                      Station and 90 miles from Jaipur Airport.
                    </p>
                    <p>
                      <span className="sb-i">By Air:</span> <br />
                      The Closest airport is Jaipur International Airport. 90
                      miles from Nahargarh by road.
                    </p>
                    <p>
                      <span className="sb-i">By Train: </span> <br />
                      Nahargarh is just 4.3 miles from Sawai Madhopur Railway
                      Station which has direct daily trains to/from Delhi.
                    </p>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel>
                    <h2>Volunteers</h2>

                    <p>
                      Thank you for your interest in volunteering at Magnetic
                      Fields Nomads You can email us a cover letter and CV at
                      ‘info @ magneticfields . in’.
                    </p>
                    <p>
                      Deadline for applications is Monday March 01. Once the
                      application phase closes, we will inform all the selected
                      volunteers via email/phone.
                    </p>
                    <p>
                      Kindly note that your application alone does not guarantee
                      a place in the volunteering team. The selection of
                      volunteers is based on available positions and compatible
                      skill sets of the applicants.
                    </p>
                  </Panel>
                </TabPanel>
                <TabPanel>
                  <Panel>
                    <h2>Sustainability</h2>

                    <p>
                      The unique location of Nahargarh makes nature an intrinsic
                      part of the event’s identity. Being just a stones throw
                      from the magnificent Ranthambhore National Park, a
                      sanctuary for tigers and an abundance of wildlife, our
                      respectful approach to conservation and sustainability
                      informs every part of Magnetic Fields Nomads.
                    </p>
                    <p>
                      We are counting on the support and cooperation of our
                      guests to help us create a space that minimises impact in
                      every sense and we will be partnering with event
                      sustainability experts to manage and monitor the way in
                      which we work with materials in the run up and pack down
                      of the weekend.
                    </p>
                    <p>
                      The ethos of this event is to create an environment rich
                      with different types of content whilst being respectful to
                      Ranthambore’s rich biodiversity and vast array of
                      wildlife.
                    </p>
                  </Panel>
                </TabPanel>

                <TabPanel>
                  <Panel>
                    <h2>Contact</h2>
                    <p>Contact Info @ magneticfields . in</p>
                    <p />
                  </Panel>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
